import { Container } from "react-bootstrap";

const PageNotFound = () => {
    return (
        <Container>
            <section>
                <h1 style={{color: "white"}}>404</h1>
            </section>
            <section>
                <h2 style={{color: "white"}}>Page Not Found!</h2>
            </section>
        </Container>
    )
}

export default PageNotFound;