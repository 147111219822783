import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import HomePage from "./pages/HomePage";
import AdminPage from "./pages/AdminPage";
import Layout from "./pages/Layout";
import PageNotFound from "./pages/PageNotFound";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage />}/>
                    <Route path="*" element={<PageNotFound />}/>
                    <Route path="admin" element={<AdminPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App;