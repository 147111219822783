import config from "../config.json";
import axios, {AxiosResponse} from "axios";

export const retrieveAccessToken = async () => {
    const authCode = sessionStorage.getItem("authCode");
    const response: AxiosResponse = await axios.post(
        config.url,
        {
            grant_type: "authorization_code",
            client_id: config.clientId,
            code: "AUTHORIZATION_CODE"
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${authCode}`
            }
        });
    console.log("Response: ", response);

    const accessToken = response.data.access_token;

    if (accessToken !== null) {
        sessionStorage.setItem("AccessToken", accessToken);
    }
}
