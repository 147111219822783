import NavBar from "../components/NavBar";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

const Layout = () => {
    return (
        <Container>
            <NavBar />
            <Outlet />
        </Container>
    )
}

export default Layout;