import { Container } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { retrieveAccessToken } from "../service/authService";

const AdminPage = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        let accessCode = queryParams.get("code");
        if (accessCode !== null) {
            sessionStorage.setItem("authCode", accessCode);
            navigate("admin");
        } else {
            const accessToken = sessionStorage.getItem("accessToken");
            if (accessToken !== null) {
                retrieveAccessToken().then(() => {
                    console.log(sessionStorage.getItem("AccessToken"));
                });
            }
        }
    });

    return (
        <Container>
            <h1 style={{color: "white"}}>AdminPage</h1>
        </Container>
    )
}

export default AdminPage;